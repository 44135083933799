
import { Component, Vue } from "vue-property-decorator";
import PublicacionLegacy from "@/components/Noticias/PublicacionLegacy.vue";

@Component({
  components: {
    PublicacionLegacy
  }
})
export default class PublicacionLegacyView extends Vue {}
